import { useEffect, useState, useCallback, useRef } from 'react';
import './App.css';
import { DentIcon, PaintIcon, TimeIcon, LocationIcon, HailIcon, BumperIcon, FleetIcon } from './components/Icons';
import logo from './assets/logo.png';
import emailjs from '@emailjs/browser';

const useIntersectionObserver = (options = {}) => {
  const [elements, setElements] = useState([]);
  const [entries, setEntries] = useState([]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(observedEntries => {
      setEntries(observedEntries);
    }, options);
  }, [options]);

  useEffect(() => {
    const currentObserver = observer.current;
    currentObserver.disconnect();

    if (elements.length > 0) {
      elements.forEach(element => currentObserver.observe(element));
    }

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [elements]);

  return [setElements, entries];
};

const generateCalendarDays = (year, month) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const days = [];
  
  // Add empty slots for days before the first day of the month
  for (let i = 0; i < firstDay.getDay(); i++) {
    days.push(null);
  }
  
  // Add all days of the month
  for (let i = 1; i <= lastDay.getDate(); i++) {
    days.push(i);
  }
  
  return days;
};

function App() {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [copied, setCopied] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const timelineRefs = useRef([]);
  const [setTimelineElements, timelineEntries] = useIntersectionObserver({
    threshold: 0.2,
  });

  useEffect(() => {
    timelineRefs.current = [];
  }, []);

  useEffect(() => {
    if (timelineRefs.current.length > 0) {
      setTimelineElements(timelineRefs.current);
    }
  }, [setTimelineElements]);

  const controlNavbar = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  }, [lastScrollY, setShow]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [controlNavbar]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleEmailClick = () => {
    window.location.href = "mailto:info@pdrdentfield.com";
  };

  const handleCopyEmail = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText("info@pdrdentfield.com")
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
  };

  useEffect(() => {
    // Force play video on mobile
    const video = document.querySelector('.video-background video');
    if (video) {
      // Try to play on page load
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Video started playing
        }).catch(() => {
          // Auto-play was prevented
          // Show a "Play" button or handle it as needed
        });
      }

      // Try to play on user interaction
      document.body.addEventListener('touchstart', () => {
        video.play();
      }, { once: true });
    }
  }, []);

  useEffect(() => {
    timelineEntries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
      }
    });
  }, [timelineEntries]);

  const [visibleItems, setVisibleItems] = useState(new Set());

  const handleIntersection = (entry, index) => {
    if (entry.isIntersecting) {
      setVisibleItems(prev => new Set([...prev, index]));
    }
  };

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedStartDay, setSelectedStartDay] = useState(null);
  const [selectedEndDay, setSelectedEndDay] = useState(null);
  const [isSelectingRange, setIsSelectingRange] = useState(false);

  const isDateInRange = (day) => {
    if (!selectedStartDay || !selectedEndDay) return false;
    return day >= selectedStartDay && day <= selectedEndDay;
  };

  // Add this state for form steps
  const [bookingStep, setBookingStep] = useState(1); // 1: Calendar, 2: Form
  const [bookingDetails, setBookingDetails] = useState({
    dates: null,
    jobDescription: '',
    numberOfCars: 1,
    damageType: '',
    carParts: '',
    additionalNotes: '',
    name: '',
    email: '',
    phone: '',
    business: ''
  });

  // Update the handleFormSubmit function
  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    // Format the dates properly
    const formattedDates = bookingDetails.dates ? 
      `${bookingDetails.dates} ${new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })}` : 
      'No dates selected';

    // Prepare the template parameters with proper formatting
    const templateParams = {
      to_email: 'info@pdrdentfield.com', // Add recipient email
      from_name: bookingDetails.name,
      from_email: bookingDetails.email,
      dates: formattedDates,
      number_of_cars: bookingDetails.numberOfCars.toString(),
      damage_type: bookingDetails.damageType || 'Not specified',
      affected_parts: bookingDetails.carParts || 'Not specified',
      job_description: bookingDetails.jobDescription || 'Not specified',
      additional_notes: bookingDetails.additionalNotes || 'None',
      contact_name: bookingDetails.name,
      contact_email: bookingDetails.email,
      contact_phone: bookingDetails.phone,
      business_name: bookingDetails.business || 'Not provided',
      message: `
        Booking Details:
        - Dates: ${formattedDates}
        - Number of Cars: ${bookingDetails.numberOfCars}
        - Damage Type: ${bookingDetails.damageType || 'Not specified'}
        - Affected Parts: ${bookingDetails.carParts || 'Not specified'}
        - Job Description: ${bookingDetails.jobDescription || 'Not specified'}
        - Additional Notes: ${bookingDetails.additionalNotes || 'None'}

        Contact Information:
        - Name: ${bookingDetails.name}
        - Email: ${bookingDetails.email}
        - Phone: ${bookingDetails.phone}
        - Business: ${bookingDetails.business || 'Not provided'}
      `
    };

    // Initialize EmailJS
    emailjs.init("0ZZDEqQieyCLYt9Ji");

    // Show loading state
    setIsTransitioning(true);

    // Send the email
    emailjs.send('service_3zgqw4r', 'template_ad84fib', templateParams)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // Reset form
        setBookingStep(1);
        setSelectedStartDay(null);
        setSelectedEndDay(null);
        setBookingDetails({
          dates: null,
          jobDescription: '',
          numberOfCars: 1,
          damageType: '',
          carParts: '',
          additionalNotes: '',
          name: '',
          email: '',
          phone: '',
          business: ''
        });
        // Show success message
        alert(translations[currentLanguage].emailSentSuccess || 'Email sent successfully!');
      })
      .catch((err) => {
        console.log('FAILED...', err);
        // Show error message
        alert(translations[currentLanguage].emailSentError || 'Failed to send email. Please try again.');
      })
      .finally(() => {
        setIsTransitioning(false);
      });
  };

  // Add this state for animation
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Create a function to handle step transitions
  const handleStepChange = (nextStep) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setBookingStep(nextStep);
      setIsTransitioning(false);
    }, 400); // Match this with your CSS transition duration
  };

  // Add this state for mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Add this near the top of your file with other state declarations
  const [currentLanguage, setCurrentLanguage] = useState('de');

  // Add this state for language menu
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  // Add this object for translations
  const translations = {
    en: {
      // Navigation
      home: 'Home',
      services: 'Services',
      aboutUs: 'About Us',
      partners: 'Partners',
      contact: 'Contact',
      bookAppointment: 'Book Appointment',

      // Hero Section
      heroTitle: 'Dentfield GmbH.',
      heroProfessional: 'Professional Paintless dent repairs Europewide.',
      heroSince: 'Since 2018',
      heroRestore: 'Restore your vehicle to its original condition.',
      contactNow: 'Contact Now',

      // Partners Section
      trustHeading: 'THEY ALREADY TRUST US',

      // Services Section
      ourServices: 'OUR SERVICES',
      // Service Cards
      paintlessDentRepair: {
        title: 'Paintless Dent Repair',
        description: 'Professional dent removal without damaging the original paint. Perfect for hail damage and parking dents.'
      },
      hailDamage: {
        title: 'Hail Damage Repair',
        description: 'Specialized techniques for repairing hail damage while preserving the original paint.'
      },
      paintCorrection: {
        title: 'Paint Correction',
        description: 'Color matched scratch filling and polishing for a flawless finish.'
      },
      expressService: {
        title: 'Express Service',
        description: 'Same-day repairs for minor damage, getting you back on the road quickly without compromising quality.'
      },
      mobileService: {
        title: 'Mobile Service',
        description: 'Convenient on-site repairs at your location Europewide.'
      },
      bumperRepair: {
        title: 'Bumper Repair',
        description: 'Expert repair and restoration of plastic and metal bumpers, including crack repair and repainting.'
      },
      fleetService: {
        title: 'Fleet Service',
        description: 'Comprehensive repair solutions for vehicle fleets with priority scheduling and minimal downtime.'
      },
      flexibleAppointments: {
        title: 'Flexible Appointments',
        description: 'Schedule repairs at your convenience with flexible options designed to fit your busy lifestyle.'
      },

      // Company Section
      ourCompany: 'OUR COMPANY',
      storyTitle: 'The Story of Dentfield GmbH',
      storyPart1: 'goes back to my childhood when I spent all my free time watching and working on cars. My father played a big role in this, as he was always passionate about his craft, automotive mechanics.',
      storyPart2: 'After school, it was clear that I wanted to work with cars. That\'s how I came to dent repair techniques, which brought me so much joy that I founded my company, DentField BT, 5 years ago.',

      // Timeline Section
      journey: 'OUR JOURNEY',

      // Contact Section
      contactHeading: 'Send us an email and let\'s work together...',

      // Booking Form
      bookingTitle: 'Book an Appointment',
      selectDateRange: 'Select a date range for your repair work',
      selectedDates: 'Selected dates',
      next: 'Next',
      back: 'Back',
      submit: 'Submit Booking',

      // Form Fields
      repairDetails: 'Repair Details',
      numberOfCars: 'Number of Cars',
      damageType: 'Type of Damage',
      damageTypePlaceholder: 'Please describe the type of damage (e.g., hail damage on multiple panels, deep dent on door...)',
      affectedParts: 'Affected Car Parts',
      affectedPartsPlaceholder: 'List the affected car parts (e.g., hood, right front door, roof...)',
      jobDescription: 'Job Description',
      jobDescriptionPlaceholder: 'Please describe the damage and your requirements...',
      additionalNotes: 'Additional Notes',
      additionalNotesPlaceholder: 'Any additional information...',

      // Contact Form
      contactInfo: 'Contact Information',
      fullName: 'Full Name *',
      namePlaceholder: 'Enter your full name',
      emailAddress: 'Email Address *',
      emailPlaceholder: 'Enter your email address',
      phoneNumber: 'Phone Number *',
      phonePlaceholder: 'Enter your phone number',
      businessName: 'Business Name (Optional)',
      businessPlaceholder: 'Enter your business name if applicable',

      // Footer
      professionalRepairs: 'Professional Paintless dent repairs Europewide.',
      allRightsReserved: 'All Rights Reserved.',

      timeline: {
        2019: "In Suzuki Csorna, performing hail damage repairs on insured cars.",
        2020: "Expanded operations to Italy, working with Lever Touch company.",
        2021: "Partnered with Dellen Technik in Upper Austria, serving Salzburg and Langschlag regions.",
        2022: "International expansion to Belgium, working at the Hail Centre Brussels.",
        2023: "Renewed partnership with Dellen Technik, focusing on the Vienna area.",
        2024: "Collaborating with Hödlmayer company in Hungary for hail repair on new vehicles."
      },
      emailSentSuccess: 'Your booking request has been sent successfully!',
      emailSentError: 'Failed to send booking request. Please try again.',
      ourWorks: 'OUR WORKS',
      workDescription: 'Check out our repair process and results',
      viewMoreLinkedIn: 'More Videos on LinkedIn',
      viewMoreTikTok: 'More Videos on TikTok',
    },

    de: {
      // Navigation
      home: 'Startseite',
      services: 'Leistungen',
      aboutUs: 'Über uns',
      partners: 'Partner',
      contact: 'Kontakt',
      bookAppointment: 'Termin Buchen',

      // Hero Section
      heroTitle: 'Dentfield GmbH.',
      heroProfessional: 'Professionelle ausbeularbeiten ohne Lackierung europaweit.',
      heroSince: 'Seit 2018',
      heroRestore: 'Bringen Sie Ihr Fahrzeug wieder in seinen Originalzustand.',
      contactNow: 'Jetzt Kontaktieren',

      // Partners Section
      trustHeading: 'SIE VERTRAUEN UNS BEREITS',

      // Services Section
      ourServices: 'UNSERE LEISTUNGEN',
      // Service Cards
      paintlessDentRepair: {
        title: 'Dellenentfernung ohne Lackierung',
        description: 'Professionelle Dellenentfernung ohne Beschädigung des Originallacks. Perfekt für Hagelschäden und Parkdellen.'
      },
      hailDamage: {
        title: 'Hagelschaden Reparatur',
        description: 'Unsere erfahrene Fachleute kümmern sich mit Qualitätswerkzeugen um das Fahrzeug.Lackierungskorrektion ausgeschlossen!'
      },
      paintCorrection: {
        title: 'Lackkorrektur',
        description: 'Farblich abgestimmte Kratzerentfernung und Politur für ein makelloses Finish.'
      },
      expressService: {
        title: 'Express Service',
        description: 'Reparaturen am selben Tag für kleinere Schäden, damit Sie schnell wieder auf der Straße sind.'
      },
      mobileService: {
        title: 'Mobiler Service',
        description: 'Bequeme Vor-Ort-Reparaturen an Ihrem Standort europaweit.'
      },
      bumperRepair: {
        title: 'Stoßstangen Reparatur',
        description: 'Professionelle Reparatur von Kunststoff und Metallstoßstangen.'
      },
      fleetService: {
        title: 'Flottenservice',
        description: 'Umfassende Reparaturlösungen für Fahrzeugflotten mit vorrangiger Terminplanung.'
      },
      flexibleAppointments: {
        title: 'Flexible Termine',
        description: 'Planen Sie Reparaturen nach Ihren Wünschen mit flexiblen Optionen.'
      },

      // Company Section
      ourCompany: 'UNSER UNTERNEHMEN',
      storyTitle: 'Die Geschichte der Dentfield GmbH',
      storyPart1: 'Ich interessiere mich seit meiner Kindheit leidenschaftlich für Autos. Ich verbrachte fast meine gesamte Freizeit damit, Autos zu beobachten und zu reparieren, wozu auch mein Vater einen großen Beitrag leistete. Er selbst war ein begeisterter und engagierter Automechaniker, und diese Leidenschaft wurde an mich weitergegeben.',
      storyPart2: 'Nach meinem Schulabschluss war für mich klar, dass Autos mein Beruf werden würden. So begann ich mich auf Dellenreparaturtechniken zu spezialisieren. Ein Bereich, der schnell zu meiner Leidenschaft wurde. Dank dieser Begeisterung gründete ich vor 5 Jahren mein eigenes Unternehmen, die Dentfield GmbH, die ihren Kunden seitdem hochwertige und vertrauliche Dienstleistungen bietet.',

      // Timeline Section
      journey: 'UNSERE REISE',

      // Contact Section
      contactHeading: 'Senden Sie uns eine E-Mail und lassen Sie uns zusammenarbeiten...',

      // Booking Form
      bookingTitle: 'Termin Buchen',
      selectDateRange: 'Wählen Sie einen Zeitraum für Ihre Reparatur',
      selectedDates: 'Ausgewählte Termine',
      next: 'Weiter',
      back: 'Zurück',
      submit: 'Buchung Absenden',

      // Form Fields
      repairDetails: 'Reparaturdetails',
      numberOfCars: 'Anzahl der Fahrzeuge',
      damageType: 'Art des Schadens',
      damageTypePlaceholder: 'Bitte beschreiben Sie die Art des Schadens (z.B. Hagelschaden, Delle an der Tür...)',
      affectedParts: 'Betroffene Fahrzeugteile',
      affectedPartsPlaceholder: 'Listen Sie die betroffenen Fahrzeugteile auf (z.B. Motorhaube, rechte Vordertür, Dach...)',
      jobDescription: 'Auftragsbeschreibung',
      jobDescriptionPlaceholder: 'Bitte beschreiben Sie den Schaden und Ihre Anforderungen...',
      additionalNotes: 'Zusätzliche Anmerkungen',
      additionalNotesPlaceholder: 'Weitere Informationen...',

      // Contact Form
      contactInfo: 'Kontaktinformationen',
      fullName: 'Vollständiger Name *',
      namePlaceholder: 'Geben Sie Ihren vollständigen Namen ein',
      emailAddress: 'E-Mail-Adresse *',
      emailPlaceholder: 'Geben Sie Ihre E-Mail-Adresse ein',
      phoneNumber: 'Telefonnummer *',
      phonePlaceholder: 'Geben Sie Ihre Telefonnummer ein',
      businessName: 'Firmenname (Optional)',
      businessPlaceholder: 'Geben Sie Ihren Firmennamen ein, falls zutreffend',

      // Footer
      professionalRepairs: 'Professionelle ausbeularbeiten ohne Lackierung europaweit.',
      allRightsReserved: 'Alle Rechte vorbehalten.',

      timeline: {
        2019: "In der Nähe von Györ (Csorna), haben wir nach einem riesigen Unwetterschädigung beim Reparaturen uns beteiligt beim Suzuki Molnar.",
        2020: "Nahe Milano mit Zusammenarbeit mit der Firma Lever Touch, haben wir über 500 Autos in ihrem alten Glanz wiederhergestellt.",
        2021: "Mit der Firma Dellen Technik in Langschlag und seiner Region in Oberösterreich haben wir den ganzen Sommer über in mehreren Markenservices und Karosserieschlosserwerkstätten gearbeitet.",
        2022: "Es war eine lange Saison in Belgien mit dem Hail Center. Wir waren von Mai bis November in und rund um Brüssel. Wir haben rund 300 durch Eis beschädigte Autos repariert.",
        2023: "PDT Reparaturen mit der Firma Dellen Technik nach Anfang Juli in Region Wien.",
        2024: "Waren wir beim Hödlmayer International wo uns Neuwagenreparaturen herausgefordert haben, aber wir haben die Arbeit mit großem Erfolg bestanden."
      },
      emailSentSuccess: 'Ihre Buchungsanfrage wurde erfolgreich gesendet!',
      emailSentError: 'Buchungsanfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
      ourWorks: 'UNSERE ARBEITEN',
      workDescription: 'Sehen Sie sich unseren Reparaturprozess und die Ergebnisse an',
      viewMoreLinkedIn: 'Weitere Videos auf LinkedIn',
      viewMoreTikTok: 'Weitere Videos auf TikTok',
    },

    hu: {
      // Navigation
      home: 'Főoldal',
      services: 'Szolgáltatások',
      aboutUs: 'Rólunk',
      partners: 'Partnereink',
      contact: 'Kapcsolat',
      bookAppointment: 'Időpontfoglalás',

      // Hero Section
      heroTitle: 'Dentfield GmbH.',
      heroProfessional: 'Professzionális horpadásjavítás fényezés nélkül Európa-szerte.',
      heroSince: '2018 óta',
      heroRestore: 'Állítsa vissza járművét eredeti állapotába.',
      contactNow: 'Kapcsolatfelvétel',

      // Partners Section
      trustHeading: 'ŐK MÁR MEGBÍZNAK BENNÜNK',

      // Services Section
      ourServices: 'SZOLGÁLTATÁSAINK',
      // Service Cards
      paintlessDentRepair: {
        title: 'Horpadásjavítás Fényezés Nélkül',
        description: 'Professzionális horpadásjavítás az eredeti fényezés sérülése nélkül. Tökéletes jégkár és parkolási sérülések esetén.'
      },
      hailDamage: {
        title: 'Jégkár Javítás',
        description: 'Speciális technikák jégkár javításához az eredeti fényezés megőrzésével.'
      },
      paintCorrection: {
        title: 'Fényezés Korrekció',
        description: 'Színre illesztett karcolásjavítás és polírozás a tökéletes felletért.'
      },
      expressService: {
        title: 'Expressz Szolgáltatás',
        description: 'Aznapi javítások kisebb sérülések esetén, hogy gyorsan újra úton lehessen.'
      },
      mobileService: {
        title: 'Mobil Szolgáltatás',
        description: 'Kényelmes helyszíni javítások az Ön tartózkodási helyén Európa-szerte.'
      },
      bumperRepair: {
        title: 'Lökhárító Javítás',
        description: 'Műanyag és fém lökhárítók szakszerű javítása.'
      },
      fleetService: {
        title: 'Flottaszolgáltatás',
        description: 'Átfogó javítási megoldások járműflották számára, elsőbbségi ütemezéssel.'
      },
      flexibleAppointments: {
        title: 'Rugalmas Időpontok',
        description: 'Tervezze meg a javításokat az Ön igényei szerint rugalmas lehetőségekkel.'
      },

      // Company Section
      ourCompany: 'CÉGÜNK',
      storyTitle: 'A Dentfield GmbH története',
      storyPart1: 'Gyermekkorom óta szenvedélyesen érdeklődöm az autók iránt. Szinte minden szabadidőmet az autók megfigyelésével és szerelésével töltöttem, amihez édesapám is nagyban hozzájárult. Ő maga is lelkes és elhivatott autószerelő volt, és ez a szenvedély rám is átragadt.',
      storyPart2: 'Az iskola befejezése után egyértelművé vált számomra, hogy az autók lesznek a hivatásom. Így kezdtem el specializálódni a horpadásjavítási technikákra, amely terület hamar a szenvedélyemmé vált. Ennek a lelkesedésnek köszönhetően 5 évvel ezelőtt megalapítottam saját cégemet, a Dentfield BT-t, amely azóta is minőségi munkát és megbízható szolgáltatást nyújt ügyfeleinek.',

      // Timeline Section
      journey: 'UTUNK',

      // Contact Section
      contactHeading: 'Küldjön nekünk e-mailt és dolgozzunk együtt...',

      // Booking Form
      bookingTitle: 'Időpontfoglalás',
      selectDateRange: 'Válasszon időtartamot a javításhoz',
      selectedDates: 'Kiválasztott időpontok',
      next: 'Tovább',
      back: 'Vissza',
      submit: 'Foglalás Küldése',

      // Form Fields
      repairDetails: 'Javítási Részletek',
      numberOfCars: 'Autók száma',
      damageType: 'Sérülés típusa',
      damageTypePlaceholder: 'Kérjük, írja le a sérülés típusát (pl. jégkár, horpadás az ajtón...)',
      affectedParts: 'Érintett alkatrészek',
      affectedPartsPlaceholder: 'Sorolja fel az érintett alkatrészeket (pl. motorháztető, jobb első ajtó, tető...)',
      jobDescription: 'Munka leírása',
      jobDescriptionPlaceholder: 'Kérjük, írja le a sérülést és az igényeit...',
      additionalNotes: 'További megjegyzések',
      additionalNotesPlaceholder: 'Bármilyen további információ...',

      // Contact Form
      contactInfo: 'Kapcsolattartási Információk',
      fullName: 'Teljes név *',
      namePlaceholder: 'Adja meg teljes nevét',
      emailAddress: 'E-mail cím *',
      emailPlaceholder: 'Adja meg e-mail címét',
      phoneNumber: 'Telefonszám *',
      phonePlaceholder: 'Adja meg telefonszámát',
      businessName: 'Cégnév (Opcionális)',
      businessPlaceholder: 'Adja meg cége nevét, ha van',

      // Footer
      professionalRepairs: 'Professzionális horpadásjavítás fényezés nélkül Európa-szerte.',
      allRightsReserved: 'Minden jog fenntartva.',

      timeline: {
        2019: "Győr mellett(Csorna) nagyobb jégeső elhárításaban vettunk részt a Suzuki Molnárnál.",
        2020: "Olaszországban voltunk Miláno mellett a Lever Touch céggel. Körülbelül 500 jégkáros autón dolgoztunk.",
        2021: "Partnerség a Dellen Technik-kel Felső-Ausztriában, Salzburg és Langschlag régiók kiszolgálása.",
        2022: "Hosszú szezon volt Belgiumba a Hail Centerrel. Májustól egészen Novemberig Brüsszel és térségében voltunk. Körübelül 300 jégkáros autót javítottunk meg.",
        2023: "Bécs régióban július környékén történő jégkár javításában vettünk részt a Dellen Technikkel.",
        2024: "Hödlmayer International-nál voltunk ahol új autókat javítottunk. Nagy kihívás volt de sikeresen helyt álltunk."
      },
      emailSentSuccess: 'Foglalási kérelmét sikeresen elküldtük!',
      emailSentError: 'A foglalási kérelem küldése sikertelen. Kérjük, próbálja újra.',
      ourWorks: 'MUNKÁINK',
      workDescription: 'Tekintse meg javítási folyamatainkat és eredményeinket',
      viewMoreLinkedIn: 'További videók LinkedInen',
      viewMoreTikTok: 'További videók TikTokon',
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.language-selector')) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisibleItems(prev => new Set([...prev, timelineRefs.current.indexOf(entry.target)]));
          }
        });
      },
      { threshold: 0.2 }
    );

    timelineRefs.current.forEach(el => {
      if (el) observer.observe(el);
    });

    return () => observer.disconnect();
  }, [timelineRefs.current]);

  // Add this state
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  // Add this effect to prevent body scroll when menu is open
  useEffect(() => {
    if (isSideMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isSideMenuOpen]);

  return (
    <div className="App">
      <nav className={`navbar ${isScrolled ? 'navbar-solid' : 'navbar-transparent'}`}>
        <img src={logo} alt="PDR Dentfield Logo" className="nav-logo" />
        <button 
          className="menu-button" 
          onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
          aria-label="Toggle menu"
        >
          {isSideMenuOpen ? '×' : '☰'}
        </button>
        <div className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <a href="#home" onClick={(e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>{translations[currentLanguage].home}</a>
          <a href="#services" onClick={(e) => {
            e.preventDefault();
            document.querySelector('.services').scrollIntoView({ behavior: 'smooth' });
          }}>{translations[currentLanguage].services}</a>
          <a href="#about" onClick={(e) => {
            e.preventDefault();
            document.querySelector('.company').scrollIntoView({ behavior: 'smooth' });
          }}>{translations[currentLanguage].aboutUs}</a>
          <a href="#partners" onClick={(e) => {
            e.preventDefault();
            document.querySelector('.partners').scrollIntoView({ behavior: 'smooth' });
          }}>{translations[currentLanguage].partners}</a>
          <a href="#contact" onClick={(e) => {
            e.preventDefault();
            document.querySelector('.contact').scrollIntoView({ behavior: 'smooth' });
          }}>{translations[currentLanguage].contact}</a>
        </div>
        <div className="navbar-right">
          <div className="social-links">
            <a href="https://www.linkedin.com/in/gergely-kormos-58b853316/" target="_blank" rel="noopener noreferrer">
              <img src={require('./assets/linkedin.png')} alt="LinkedIn" />
            </a>
            <a href="https://www.tiktok.com/@dentfield" target="_blank" rel="noopener noreferrer">
              <img src={require('./assets/tiktok.png')} alt="TikTok" />
            </a>
          </div>
          <div className="language-selector">
            <button 
              className="language-icon"
              onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
            >
              <span className="material-symbols-outlined">language</span>
            </button>
            <div className={`language-options ${isLanguageMenuOpen ? 'show' : ''}`}>
              <button 
                className={currentLanguage === 'de' ? 'active' : ''} 
                onClick={() => {
                  setCurrentLanguage('de');
                  setIsLanguageMenuOpen(false);
                }}
              >
                DE
              </button>
              <button 
                className={currentLanguage === 'en' ? 'active' : ''} 
                onClick={() => {
                  setCurrentLanguage('en');
                  setIsLanguageMenuOpen(false);
                }}
              >
                EN
              </button>
              <button 
                className={currentLanguage === 'hu' ? 'active' : ''} 
                onClick={() => {
                  setCurrentLanguage('hu');
                  setIsLanguageMenuOpen(false);
                }}
              >
                HU
              </button>
            </div>
          </div>
          <button className="book-appointment" onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
            {translations[currentLanguage].bookAppointment}
          </button>
        </div>
      </nav>

      <div 
        className={`side-menu-overlay ${isSideMenuOpen ? 'active' : ''}`}
        onClick={() => setIsSideMenuOpen(false)}
      ></div>
      <div className={`side-menu ${isSideMenuOpen ? 'active' : ''}`}>
        <button 
          className="side-menu-close"
          onClick={() => setIsSideMenuOpen(false)}
        >
          ×
        </button>
        <div className="side-menu-content">
          <nav className="side-menu-nav">
            <a 
              href="#home" 
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setIsSideMenuOpen(false);
              }}
            >
              {translations[currentLanguage].home}
            </a>
            <a 
              href="#services" 
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('.services').scrollIntoView({ behavior: 'smooth' });
                setIsSideMenuOpen(false);
              }}
            >
              {translations[currentLanguage].services}
            </a>
            <a 
              href="#about" 
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('.company').scrollIntoView({ behavior: 'smooth' });
                setIsSideMenuOpen(false);
              }}
            >
              {translations[currentLanguage].aboutUs}
            </a>
            <a 
              href="#partners" 
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('.partners').scrollIntoView({ behavior: 'smooth' });
                setIsSideMenuOpen(false);
              }}
            >
              {translations[currentLanguage].partners}
            </a>
            <a 
              href="#contact" 
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('.contact').scrollIntoView({ behavior: 'smooth' });
                setIsSideMenuOpen(false);
              }}
            >
              {translations[currentLanguage].contact}
            </a>
          </nav>

          <div className="side-menu-socials">
            <a href="https://www.linkedin.com/in/gergely-kormos-58b853316/" target="_blank" rel="noopener noreferrer">
              <img src={require('./assets/linkedin.png')} alt="LinkedIn" />
            </a>
            <a href="https://www.tiktok.com/@dentfield" target="_blank" rel="noopener noreferrer">
              <img src={require('./assets/tiktok.png')} alt="TikTok" />
            </a>
          </div>

          <div className="side-menu-language">
            <button 
              className={currentLanguage === 'de' ? 'active' : ''} 
              onClick={() => setCurrentLanguage('de')}
            >
              DE
            </button>
            <button 
              className={currentLanguage === 'en' ? 'active' : ''} 
              onClick={() => setCurrentLanguage('en')}
            >
              EN
            </button>
            <button 
              className={currentLanguage === 'hu' ? 'active' : ''} 
              onClick={() => setCurrentLanguage('hu')}
            >
              HU
            </button>
          </div>

          <div className="side-menu-book">
            <button onClick={() => {
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
              setIsSideMenuOpen(false);
            }}>
              {translations[currentLanguage].bookAppointment}
            </button>
          </div>
        </div>
      </div>

      <section className="hero" id="home">
        <div className="video-background">
          <video 
            autoPlay 
            loop 
            muted 
            playsInline
            preload="metadata"
            loading="lazy"
          >
            <source 
              src={require('./assets/herointro-mobile.mp4')} 
              type="video/mp4"
              media="(max-width: 768px)"
            />
            <source 
              src={require('./assets/herointro.mp4')} 
              type="video/mp4"
              media="(min-width: 769px)"
            />
          </video>
        </div>
        <div className="hero-content">
          <h1>{translations[currentLanguage].heroTitle}</h1>
          <p>{translations[currentLanguage].heroProfessional}</p>
          <p>{translations[currentLanguage].heroSince}</p>
          <p>{translations[currentLanguage].heroRestore}</p>
          <button className="work-with-me" onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
            {translations[currentLanguage].contactNow}
          </button>
        </div>
      </section>

      <section className="partners" id="partners">
        <h2>{translations[currentLanguage].trustHeading}</h2>
        <div className="partner-logos">
          <img src={require('./assets/mercedes-benz.png')} alt="Mercedes-Benz" />
          <img src={require('./assets/levertouch.png')} alt="LeverTouch" />
          <img src={require('./assets/suzuki.png')} alt="Suzuki" />
          <img src={require('./assets/audi-hungaria.png')} alt="Audi Hungaria" />
          <img src={require('./assets/autokozpont.png')} alt="Autokozpont" />
          <img src={require('./assets/hoedlmayr.png')} alt="Hoedlmayr" />
        </div>
      </section>

      <section className="services" id="services">
        <h2>{translations[currentLanguage].ourServices}</h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="service-icon">
              <DentIcon />
            </div>
            <h3>{translations[currentLanguage].paintlessDentRepair.title}</h3>
            <p>{translations[currentLanguage].paintlessDentRepair.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <HailIcon />
            </div>
            <h3>{translations[currentLanguage].hailDamage.title}</h3>
            <p>{translations[currentLanguage].hailDamage.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <PaintIcon />
            </div>
            <h3>{translations[currentLanguage].paintCorrection.title}</h3>
            <p>{translations[currentLanguage].paintCorrection.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <TimeIcon />
            </div>
            <h3>{translations[currentLanguage].expressService.title}</h3>
            <p>{translations[currentLanguage].expressService.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <LocationIcon />
            </div>
            <h3>{translations[currentLanguage].mobileService.title}</h3>
            <p>{translations[currentLanguage].mobileService.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <BumperIcon />
            </div>
            <h3>{translations[currentLanguage].bumperRepair.title}</h3>
            <p>{translations[currentLanguage].bumperRepair.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FleetIcon />
            </div>
            <h3>{translations[currentLanguage].fleetService.title}</h3>
            <p>{translations[currentLanguage].fleetService.description}</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <BumperIcon />
            </div>
            <h3>{translations[currentLanguage].flexibleAppointments.title}</h3>
            <p>{translations[currentLanguage].flexibleAppointments.description}</p>
          </div>

        </div>
      </section>
      

      <section className="company" id="about">
        <div className="company-content">
          <div className="company-left">
            <h2>{translations[currentLanguage].ourCompany} <img src={require('./assets/wave.png')} alt="wave" className="wave-emoji" /></h2>
            <div className="company-text">
              <h3>{translations[currentLanguage].storyTitle}</h3>
              <p>
                {translations[currentLanguage].storyPart1}
              </p>
              <p>
                {translations[currentLanguage].storyPart2}
              </p>
            </div>
          </div>
          <div className="company-image">
            <img src={require('./assets/work.png')} alt="Work" />
          </div>
        </div>
      </section>

      <section className="timeline">
        <h2>{translations[currentLanguage].journey}</h2>
        <div className="timeline-container">
          <div className="timeline-line"></div>
          
          {[2019, 2020, 2021, 2022, 2023, 2024].map((year, index) => (
            <div
              key={year}
              className={`timeline-item ${visibleItems.has(index) ? 'visible' : ''}`}
              ref={el => {
                if (el && !timelineRefs.current.includes(el)) {
                  timelineRefs.current.push(el);
                }
              }}
            >
              <div className="timeline-dot"></div>
              <div className="timeline-content">
                <div className="timeline-year">{year}</div>
                <div className="timeline-description">
                  {translations[currentLanguage].timeline[year]}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="contact-content">
          <h2>{translations[currentLanguage].contactHeading}</h2>
          <div 
            className="email-container" 
            onClick={handleEmailClick}
            onKeyPress={(e) => e.key === 'Enter' && handleEmailClick()}
            tabIndex={0}
            role="button"
            aria-label="Send email to info@pdrdentfield.com"
          >
            <span className={`email ${copied ? 'copied' : ''}`} onClick={handleCopyEmail}>
              info@pdrdentfield.com
            </span>
          </div>
        </div>
      </section>

      <section className="calendar-section">
        <div className="calendar-container">
          <div className="booking-container">
            {/* Loading indicator */}
            <div className={`step-transition ${isTransitioning ? 'active' : ''}`}>
              <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            {/* Calendar Step */}
            <div className={`booking-step ${bookingStep === 1 && !isTransitioning ? 'active' : ''}`}>
              <div className="calendar-header">
                <h3>{translations[currentLanguage].bookingTitle}</h3>
                <p>{translations[currentLanguage].selectDateRange}</p>
              </div>
              <div className="calendar-nav">
                <button onClick={() => setCurrentMonth(prev => prev - 1)}>←</button>
                <h4>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}</h4>
                <button onClick={() => setCurrentMonth(prev => prev + 1)}>→</button>
              </div>
              <div className="calendar-weekdays">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                  <div key={day} className="weekday">{day}</div>
                ))}
              </div>
              <div className="calendar-days">
                {generateCalendarDays(currentYear, currentMonth).map((day, index) => (
                  <div 
                    key={index} 
                    className={`calendar-day ${
                      isDateInRange(day) ? 'in-range' : ''
                    } ${
                      day === selectedStartDay ? 'range-start' : ''
                    } ${
                      day === selectedEndDay ? 'range-end' : ''
                    } ${
                      day ? 'has-date' : ''
                    }`}
                    onClick={() => {
                      if (!day) return;
                      
                      if (!isSelectingRange) {
                        setSelectedStartDay(day);
                        setSelectedEndDay(null);
                        setIsSelectingRange(true);
                      } else {
                        if (day < selectedStartDay) {
                          setSelectedStartDay(day);
                          setSelectedEndDay(selectedStartDay);
                        } else {
                          setSelectedEndDay(day);
                        }
                        setIsSelectingRange(false);
                      }
                    }}
                  >
                    {day}
                  </div>
                ))}
              </div>
              {(selectedStartDay && selectedEndDay) && (
                <div className="selection-info">
                  <p>
                    Selected dates: {selectedStartDay} - {selectedEndDay} {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })}
                  </p>
                  <button onClick={() => {
                    setBookingDetails(prev => ({
                      ...prev,
                      dates: `${selectedStartDay} - ${selectedEndDay} ${new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })}`
                    }));
                    handleStepChange(2);
                  }}>
                    {translations[currentLanguage].next}
                  </button>
                </div>
              )}
            </div>

            {/* Repair Details Step */}
            <div className={`booking-step ${bookingStep === 2 && !isTransitioning ? 'active' : ''}`}>
              <div className="booking-form">
                <h3>{translations[currentLanguage].repairDetails}</h3>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label>{translations[currentLanguage].selectedDates}</label>
                    <div className="selected-dates">{bookingDetails.dates}</div>
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].numberOfCars}</label>
                    <input
                      type="number"
                      id="numberOfCars"
                      min="1"
                      value={bookingDetails.numberOfCars}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        numberOfCars: parseInt(e.target.value)
                      }))}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].damageType}</label>
                    <textarea
                      id="damageType"
                      value={bookingDetails.damageType}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        damageType: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].damageTypePlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].affectedParts}</label>
                    <textarea
                      id="carParts"
                      value={bookingDetails.carParts}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        carParts: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].affectedPartsPlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].jobDescription}</label>
                    <textarea
                      id="jobDescription"
                      value={bookingDetails.jobDescription}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        jobDescription: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].jobDescriptionPlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].additionalNotes}</label>
                    <textarea
                      id="additionalNotes"
                      value={bookingDetails.additionalNotes}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        additionalNotes: e.target.value
                      }))}
                      placeholder={translations[currentLanguage].additionalNotesPlaceholder}
                    />
                  </div>

                  <div className="form-buttons">
                    <button type="button" onClick={() => handleStepChange(1)} className="back-button">
                      {translations[currentLanguage].back}
                    </button>
                    <button type="button" onClick={() => handleStepChange(3)} className="next-button">
                      {translations[currentLanguage].next}
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* Contact Information Step */}
            <div className={`booking-step ${bookingStep === 3 && !isTransitioning ? 'active' : ''}`}>
              <div className="booking-form">
                <h3>{translations[currentLanguage].contactInfo}</h3>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label>{translations[currentLanguage].fullName} *</label>
                    <input
                      type="text"
                      id="name"
                      value={bookingDetails.name}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        name: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].namePlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].emailAddress} *</label>
                    <input
                      type="email"
                      id="email"
                      value={bookingDetails.email}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        email: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].emailPlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].phoneNumber} *</label>
                    <input
                      type="tel"
                      id="phone"
                      value={bookingDetails.phone}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        phone: e.target.value
                      }))}
                      required
                      placeholder={translations[currentLanguage].phonePlaceholder}
                    />
                  </div>

                  <div className="form-group">
                    <label>{translations[currentLanguage].businessName} (Optional)</label>
                    <input
                      type="text"
                      id="business"
                      value={bookingDetails.business}
                      onChange={(e) => setBookingDetails(prev => ({
                        ...prev,
                        business: e.target.value
                      }))}
                      placeholder={translations[currentLanguage].businessPlaceholder}
                    />
                  </div>

                  <div className="form-buttons">
                    <button type="button" onClick={() => handleStepChange(2)} className="back-button">
                      {translations[currentLanguage].back}
                    </button>
                    <button type="submit" className="submit-button">
                      {translations[currentLanguage].submit}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="works">
        <div className="works-content">
          <h2>{translations[currentLanguage].ourWorks}</h2>
          <p>{translations[currentLanguage].workDescription}</p>
          <div className="works-columns">
            <div className="work-column linkedin">
              <div className="platform-header">
                <img src={require('./assets/linkedin.png')} alt="LinkedIn" />
                <h3>LinkedIn</h3>
              </div>
              <a 
                href="https://www.linkedin.com/in/gergely-kormos-58b853316/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="work-item video-link"
              >
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline
                  loading="lazy"
                >
                  <source src={require('./assets/111.mp4')} type="video/mp4" />
                </video>
                <div className="video-overlay">
                  <span className="play-icon">►</span>
                </div>
              </a>
              <a 
                href="https://www.linkedin.com/in/gergely-kormos-58b853316/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="platform-button linkedin-button"
              >
                {translations[currentLanguage].viewMoreLinkedIn}
              </a>
            </div>

            <div className="work-column tiktok">
              <div className="platform-header">
                <img src={require('./assets/tiktok.png')} alt="TikTok" />
                <h3>TikTok</h3>
              </div>
              <a 
                href="https://www.tiktok.com/@dentfield" 
                target="_blank" 
                rel="noopener noreferrer"
                className="work-item video-link"
              >
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline
                  loading="lazy"
                >
                  <source src={require('./assets/222.mp4')} type="video/mp4" />
                </video>
                <div className="video-overlay">
                  <span className="play-icon">►</span>
                </div>
              </a>
              <a 
                href="https://www.tiktok.com/@dentfield" 
                target="_blank" 
                rel="noopener noreferrer"
                className="platform-button tiktok-button"
              >
                {translations[currentLanguage].viewMoreTikTok}
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <img src={logo} alt="PDR Dentfield Logo" className="nav-logo" />
            <p>{translations[currentLanguage].professionalRepairs}</p>
          </div>
          <div className="footer-right">
            <div className="footer-contact">
              <a href="mailto:info@pdrdentfield.com">info@pdrdentfield.com</a>
              <a href="tel:+436606305738">+43 660 6305738</a>
            </div>
            <div className="footer-social">
              <a href="https://www.linkedin.com/in/gergely-kormos-58b853316/" target="_blank" rel="noopener noreferrer">
                <img src={require('./assets/linkedin.png')} alt="LinkedIn" />
              </a>
              <a href="https://www.tiktok.com/@dentfield" target="_blank" rel="noopener noreferrer">
                <img src={require('./assets/tiktok.png')} alt="TikTok" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} Dentfield GmbH. {translations[currentLanguage].allRightsReserved}</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
